import React, { useEffect, useState } from 'react'
import useAuth from 'hooks/use-auth'
import { useDispatch, useSelector } from 'react-redux'
import ProductDetails from '../product-details'
import ProvenButtonWithIntent from '../../proven-button-with-intent'
import { COMBO_PRODUCTS } from '../../../../constants/products'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../../styles/theme-proven'
import FullIngredientsListDrawer from '../full-ingredients-list-drawer'
import { isEmpty } from 'lodash'
import SubscribeAndSaveSelector from '../subscribe-and-save-selector'
import {
  activeSubscriptionsSelect,
  isNextActionOnCurrentProductPageOneTimeSelector,
  userInfoSelector
} from '../../../../utils/selectors'
import { lookForActiveSubsWhereIndividualProductCanBeAdded } from '../../../../utils/helpers'
import {
  setOneTimeOptionForNextActionOnCurrentProductPage,
  setSubscriptionOptionForNextActionOnCurrentProductPage
} from '../../../../actions/app.actions'
import ProvenButtonAddToCart from '../../proven-button-add-to-cart'
import IngredientsListDrawer from '../../ingredients-list-drawer'
import useProductsResultsData from '../../../../hooks/useProductsResultsData'
import { useAllProducts, useProductPrices } from 'services/shopify/hooks'
import { ProductProvider } from '@shopify/hydrogen-react'
import { updateAccountInfo } from '../../../../actions/account.actions'
const themeProven = createProvenTheme()

const convertYotpoProductFromBuilder = yotpo_product => {
  return {
    sku_product_id: yotpo_product?.value?.data?.productId,
    name: yotpo_product?.value?.data?.name,
    url: yotpo_product?.value?.data?.url,
    image_url: yotpo_product?.value?.data?.image_path
  }
}

function ProductDetailsBasedOnUserAuthentication({
  product: productId,
  description: descriptionForNonAuthenticatedUser,
  size: sizeForNonAuthenticatedUser,
  title: titleForNonAuthenticatedUser,
  subtitle: subtitleForNonAuthenticatedUser,
  carouselImagesForNonAuthenticatedUser,
  bulletPointsForNonAuthenticatedUser,
  horizontallyReversed,
  hideWhenIsAuthenticated = false,
  hideWhenIsNotAuthenticated = false,
  hideIngredientsList,
  hideTags,
  customTags = [],
  showSubtitleWithNameOfAuthenticatedUser,
  buttonTextForAuthenticatedUser = 'GET MY FORMULA',
  buttonTextForUnauthenticatedUser = 'GET MY FORMULA',
  legendBelowButtonOnlyOneTime = 'Try it à la carte. Free shipping.',
  addPriceInButtonWhenIsAuthenticated,
  forceOneTimeAction,
  forceSubscriptionAction,
  yotpo_product,
  intent: enforcedIntent
}) {
  const allProducts = useAllProducts()
  const activeSubscriptions = useSelector(activeSubscriptionsSelect)
  const isNextActionOnCurrentProductPageOneTime = useSelector(
    isNextActionOnCurrentProductPageOneTimeSelector
  )
  const [isSubscriptionSelected, setSubscriptionSelected] = useState(
    +!isNextActionOnCurrentProductPageOneTime
  )
  const dispatch = useDispatch()
  const yotpoProduct = convertYotpoProductFromBuilder(yotpo_product)

  const userInfo = useSelector(userInfoSelector)
  const shopifyProduct = allProducts.data?.find(someProduct =>
    someProduct.tags.includes(`id:${productId}`)
  )
  const shopifyProductPrice = useProductPrices(shopifyProduct)
  const getSubtitleWithNameOfAuthenticatedUser = name => `Made for ${name}’s Skin`

  const isThereActiveSubsWhereIndividualProductCanBeAdded =
    !COMBO_PRODUCTS.includes(productId) &&
    lookForActiveSubsWhereIndividualProductCanBeAdded(productId, activeSubscriptions)

  useEffect(() => {
    if (!isThereActiveSubsWhereIndividualProductCanBeAdded) {
      if (isNextActionOnCurrentProductPageOneTime) {
        dispatch(setSubscriptionOptionForNextActionOnCurrentProductPage())
        setSubscriptionSelected(1)
      }
    } else {
      if (!isNextActionOnCurrentProductPageOneTime) {
        dispatch(setOneTimeOptionForNextActionOnCurrentProductPage())
        setSubscriptionSelected(0)
      }
    }
  }, [dispatch])

  const handleOnSubscriptionSelectionChange = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (e.target.value && !isSubscriptionSelected) {
      setSubscriptionSelected(1)
      // TODO CHECK is we need. This is used by segment event https://proven.atlassian.net/browse/ENG-1235
      dispatch(updateAccountInfo({ isSubscriptionSelected: true }))
      dispatch(setSubscriptionOptionForNextActionOnCurrentProductPage())
    } else {
      setSubscriptionSelected(0)
      // TODO CHECK is we need. This is used by segment event https://proven.atlassian.net/browse/ENG-1235
      dispatch(updateAccountInfo({ isSubscriptionSelected: false }))
      dispatch(setOneTimeOptionForNextActionOnCurrentProductPage())
    }
  }

  const buildTags = (climate, air_quality, water_hardness) => {
    let elementsWithProductsTags = []
    if (climate?.length && !isEmpty(climate[0])) elementsWithProductsTags.push(climate[0])
    if (air_quality?.length && !isEmpty(air_quality[0]))
      elementsWithProductsTags.push(air_quality[0])
    if (water_hardness?.length && !isEmpty(water_hardness[0]))
      elementsWithProductsTags.push(water_hardness[0])

    if (skin?.length)
      elementsWithProductsTags = elementsWithProductsTags.concat(skin.filter(s => !isEmpty(s)))
    if (lifestyle?.length)
      elementsWithProductsTags = elementsWithProductsTags.concat(lifestyle.filter(l => !isEmpty(l)))

    const result = elementsWithProductsTags.reduce((tagsAccum, elementWithTag) => {
      if (elementWithTag?.tags_on_products) tagsAccum.push(elementWithTag.tags_on_products)
      return tagsAccum
    }, [])

    return result
  }

  const buildCustomTags = (slice = false) => {
    if (!customTags?.length) {
      return null
    }
    let tags = customTags.map(ct => ct.tag) || []
    if (slice) {
      tags = tags?.slice(0, 7)
    }

    return tags
  }

  const getIndividualProductsWithFullIngredients = () => {
    if (customTags?.length) {
      return [
        {
          title: '',
          fullIngredients: buildCustomTags()
        }
      ]
    }

    return isAuthenticated
      ? products?.map(p => {
          return {
            title: p.title?.toLowerCase()?.startsWith('day moisturizer')
              ? 'Day Moisturizer'
              : p.title,
            fullIngredients: p.full_ingredients
          }
        })
      : []
  }

  const { isAuthenticated } = useAuth()

  const {
    skin,
    lifestyle,
    waterHardness: water_hardness,
    airQuality: air_quality,
    climate,
    products
  } = useProductsResultsData()

  if (
    (isAuthenticated && hideWhenIsAuthenticated) ||
    (!isAuthenticated && hideWhenIsNotAuthenticated)
  ) {
    return null
  }

  const tags =
    (!hideTags && buildCustomTags(true)) || buildTags(climate, air_quality, water_hardness)

  const individualProductsWithFullIngredients = getIndividualProductsWithFullIngredients()

  const getProductFromResults = () => {
    if (!isAuthenticated) return undefined
    const product = products.find(
      p =>
        (p.productPlanGroup && p.productPlanGroup === productId) ||
        (p.productId && p.productId === productId)
    )
    if (!product) return undefined
    return product
  }

  let description, size, title, subtitle, carouselImagesUrls, features
  const productFromResults = getProductFromResults()
  if (productFromResults) {
    description = productFromResults.description
    size = productFromResults.size
    title = productFromResults.title || productFromResults.titile //TODO on ENG-1943 remove product.title once typo is fixed in backend
    subtitle =
      showSubtitleWithNameOfAuthenticatedUser && isAuthenticated
        ? getSubtitleWithNameOfAuthenticatedUser(userInfo?.firstName)
        : productFromResults.subtitle
    carouselImagesUrls =
      productFromResults?.shop_image_gallery || productFromResults?.shop_img || []
    features = (productFromResults.features || []).map((f, i) =>
      f.length ? f[0][`feature_${i + 1}`] : {}
    )
  } else {
    description = descriptionForNonAuthenticatedUser
    size = sizeForNonAuthenticatedUser
    title = titleForNonAuthenticatedUser
    subtitle =
      showSubtitleWithNameOfAuthenticatedUser && isAuthenticated
        ? getSubtitleWithNameOfAuthenticatedUser(userInfo?.firstName)
        : subtitleForNonAuthenticatedUser
    carouselImagesUrls = (carouselImagesForNonAuthenticatedUser || []).map(i => i.image)
    features = bulletPointsForNonAuthenticatedUser
  }

  if (isAuthenticated === null || !shopifyProductPrice) return null

  const subscriptionPrice = shopifyProductPrice.subscriptionPriceString
  const oneTimePrice = shopifyProductPrice.oneTimePriceString
  const sellingPlanStr = shopifyProductPrice.sellingPlanStr

  const actionButton = isAuthenticated ? (
    <ProductProvider data={shopifyProduct}>
      <ProvenButtonAddToCart
        color="secondary"
        variant="contained"
        forceSubscriptionAction={forceSubscriptionAction}
        forceOneTimeAction={forceOneTimeAction}
        textToShowForAuthenticated={buttonTextForAuthenticatedUser}
        textToShowForUnauthenticated={buttonTextForUnauthenticatedUser}
        textToShowForAddingToSubscription="CONFIRM TO ADD"
        addPriceWhenIsAuthenticated={addPriceInButtonWhenIsAuthenticated}
        productId={productId}
        addSavingCancellingAndShippingLegend={false}
        fullWidth={false}
      />
    </ProductProvider>
  ) : (
    <ProvenButtonWithIntent
      text={buttonTextForUnauthenticatedUser}
      variant="contained"
      color="secondary"
      intent={enforcedIntent}
    />
  )

  return (
    <ThemeProvider theme={themeProven}>
      <ProductDetails
        product={shopifyProduct}
        horizontallyReversed={horizontallyReversed}
        size={size}
        title={title}
        tags={tags}
        subtitle={subtitle}
        features={features || []}
        description={description}
        carouselImagesUrls={carouselImagesUrls || []}
        legendBelowButton={forceOneTimeAction ? legendBelowButtonOnlyOneTime : undefined}
        subscribeAndSaveSelector={
          isAuthenticated && !forceSubscriptionAction && !forceOneTimeAction ? (
            <SubscribeAndSaveSelector
              productId={productId}
              subscriptionPrice={subscriptionPrice}
              oneTimePrice={oneTimePrice}
              isSubscriptionSelected={isSubscriptionSelected}
              isAlreadyThereAnActiveSubscription={isThereActiveSubsWhereIndividualProductCanBeAdded}
              onSubscriptionSelectionChange={handleOnSubscriptionSelectionChange}
              sellingPlan={sellingPlanStr}
            />
          ) : undefined
        }
        actionButton={actionButton}
        renderIngredientsListDrawer={
          isAuthenticated && !hideIngredientsList
            ? (isOpen, handleClose) =>
                productFromResults ? (
                  <IngredientsListDrawer
                    ingredients={productFromResults.ingredients}
                    fullIngredients={productFromResults.full_ingredients}
                    open={isOpen}
                    handleClose={handleClose}
                  />
                ) : (
                  <FullIngredientsListDrawer
                    individualProductsWithFullIngredients={individualProductsWithFullIngredients}
                    open={isOpen}
                    handleClose={handleClose}
                  />
                )
            : undefined
        }
        yotpoProduct={yotpoProduct}
      />
    </ThemeProvider>
  )
}
export default ProductDetailsBasedOnUserAuthentication
