import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography, Stack, Box, useMediaQuery, CircularProgress } from '@mui/material'
import propTypes from 'prop-types'
import ProvenButtonWithIntent from '../proven-button-with-intent'
import useAuth from 'hooks/use-auth'
import useCurrency from '../../../hooks/useCurrency'
import { PRODUCT_ID_BY_INTENT } from '../../../constants/products'
import { isNextActionOnCurrentProductPageOneTimeSelector } from '../../../utils/selectors'
import AddToSubscriptionConfirmation from './add-to-subscription-confirmation'
import SavingLegend from './saving-legend'
import { createProvenTheme } from '../../../styles/theme-proven'
import { useContext } from 'react'
import { IntentAndFunnelsContext } from '../../../providers/IntentAndFunnelsProvider'
import { KEYWORD_SKIN } from '../../../constants/funnels'
import { useProduct } from '@shopify/hydrogen-react'
import {
  useCartActions,
  useProductPrices,
  useSubscriptionMutations,
  useSubscriptions
} from 'services/shopify/hooks'
import { createNotification } from '../../../actions/app.actions'

const themeProven = createProvenTheme()
const spacing = themeProven.spacing

const ProvenButtonAddToCart = ({
  textToShowForAuthenticated,
  textToShowForUnauthenticated,
  textToShowForAddingToSubscription,
  productId, // Shopify product id
  forceSubscriptionAction,
  forceOneTimeAction,
  addPriceWhenIsAuthenticated,
  addSavingCancellingAndShippingLegend,
  variant = 'contained',
  color = 'secondary',
  size = 'medium',
  'data-cy': dataCy,
  intent: enforcedIntent,
  fullWidth = false,
  sx = {},
  showPrice = true
}) => {
  const dispatch = useDispatch()
  const cartActions = useCartActions()
  const { isAuthenticated } = useAuth()
  const { intent: currentContextIntent } = useContext(IntentAndFunnelsContext)
  const { currencySymbol, currencyToDisplayOrEmptyIfUS } = useCurrency()
  const activeSubscriptions = useSubscriptions()
  const isOneTimeSelected = useSelector(isNextActionOnCurrentProductPageOneTimeSelector)
  // little tricky here, if this component is used in other component then the data is in product prop, but if it came from a symbol the data is in "data"
  const { product: shopifyProductRef } = useProduct()
  const shopifyProduct = shopifyProductRef?.data || shopifyProductRef
  const shopifyProductPrice = useProductPrices(shopifyProduct)

  const isSubscriptionAction =
    (!!forceSubscriptionAction && !forceOneTimeAction) || //if forceSubscriptionAction, action will be subscription no matter next conditions
    (!(!!forceOneTimeAction && !forceSubscriptionAction) && //if forceOneTimeAction, action will be one-time no matter next conditions
      !isOneTimeSelected) //if neither forceSubscriptionAction nor forceOneTimeAction is in true, action will be based on isOneTimeSelected value.
  //if forceSubscriptionAction and forceOneTimeAction are both in true, action will also be based on isOneTimeSelected value, since it is not a valid case

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))
  const subscriptionMutations = useSubscriptionMutations()

  const handleAddProductToNextSubscription = async (product, sellingPlanId) => {
    subscriptionMutations.addItem.mutate(
      {
        itemToAdd: {
          product,
          sellingPlanId
        }
      },
      {
        onSuccess: () => {
          dispatch(
            createNotification(
              'Success!',
              'Items successfully added your subscription. You can manage it in Subscription'
            )
          )
        }
      }
    )
  }

  const handleAddToSubscription = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    handleAddProductToNextSubscription(shopifyProduct, shopifyProduct.initialSellingPlanId)
  }

  // If not authenticated, send the user to the quiz.
  if (!isAuthenticated)
    return (
      <ProvenButtonWithIntent
        variant={variant}
        color={color}
        text={textToShowForUnauthenticated}
        intent={enforcedIntent}
        data-cy={dataCy}
        fullWidth={fullWidth}
        sx={{ ...sx, width: fullWidth ? sx.width : 'fit-content' }}
      />
    )

  const getProductIdBasedOnIntent = () => {
    const intent = enforcedIntent || currentContextIntent || KEYWORD_SKIN
    // the intent logic was disabled, no need to console a waring
    // if (!enforcedIntent && !currentContextIntent) {
    //   console.warn(
    //     `in ProvenButtonAddToCart, there is neither a productId nor a enforcedIntent nor a context intent. it'll be used ${KEYWORD_SKIN} as default to get the productId. It may be a temporary state between renders or a desired behavior`
    //   )
    // }
    return PRODUCT_ID_BY_INTENT[intent]
  }

  const productIdSelectedOrBasedOnIntent = productId || getProductIdBasedOnIntent()
  if (!productIdSelectedOrBasedOnIntent) {
    console.warn(
      'ProvenButtonAddToCart for authenticated users requires a valid productIdSelectedOrBasedOnIntent, in order to know which product will be added into the cart.' +
        ' actual productIdSelectedOrBasedOnIntent:' +
        productIdSelectedOrBasedOnIntent
    )
    return null
  }

  // If authenticated, add the selected product to cart or to current subscription.
  const getButtonText = ({ defaultPrice, fullPrice, isAddingToSubscription, isForceOneTime }) => {
    if (isAddingToSubscription || !addPriceWhenIsAuthenticated)
      return isAddingToSubscription && textToShowForAddingToSubscription
        ? textToShowForAddingToSubscription
        : textToShowForAuthenticated

    // this logic is to show in one-time products the fullPrice
    const displayPrice = isForceOneTime ? fullPrice : defaultPrice
    const oldPriceFormatted = fullPrice ? null : fullPrice

    return (
      <Box component="p" width="max-content">
        <Typography component="span" variant="footnote" color="gray.elysian">
          {showPrice
            ? `${textToShowForAuthenticated} - ${currencySymbol}${displayPrice}${currencyToDisplayOrEmptyIfUS}`
            : `${textToShowForAuthenticated}`}
        </Typography>
        {showPrice && oldPriceFormatted && (
          <>
            <Typography component="span" variant="footnote" color="gray.elysian">
              &nbsp;
            </Typography>
            <Typography
              component="s"
              variant="footnote"
            >{`${currencySymbol}${oldPriceFormatted}`}</Typography>
          </>
        )}
      </Box>
    )
  }

  const renderAddToCartButton = shopifyProductPrice => {
    const fullPrice = shopifyProductPrice.oneTimePriceString
    const defaultPrice = shopifyProductPrice.subscriptionPriceString
    const periodPlanStr = shopifyProductPrice.sellingPlanStr
    const discountAmountInCents = shopifyProductPrice.discount
    const isOneTime = forceOneTimeAction || !isSubscriptionAction

    const button = (
      <Button
        size={size}
        variant={variant}
        color={color}
        onClick={e => {
          if (e) {
            e.preventDefault()
            e.stopPropagation()
          }
          cartActions.add({
            product: shopifyProduct,
            sellingPlanId: isSubscriptionAction ? shopifyProduct.initialSellingPlanId : undefined
          })
        }}
        data-cy={dataCy}
        fullWidth={fullWidth}
        sx={{ ...sx, width: fullWidth ? sx.width : 'fit-content' }}
      >
        {getButtonText({ defaultPrice, fullPrice, isForceOneTime: isOneTime })}
      </Button>
    )

    return addSavingCancellingAndShippingLegend && discountAmountInCents ? (
      <Stack gap={2} pb={{ md: 3, lg: 0 }} position="relative">
        {button}
        <SavingLegend
          sx={isMdUp ? { position: 'absolute', top: spacing(8) } : undefined}
          sellingPlan={periodPlanStr}
          isOneTime={!isSubscriptionAction}
          savingAmount={discountAmountInCents}
        />
      </Stack>
    ) : (
      button
    )
  }

  const renderAddToCurrentSubscriptionButton = (currentSubscription, shopifyProductPrice) => {
    const defaultPrice = shopifyProductPrice.oneTimePriceString
    const fullPrice = shopifyProductPrice.subscriptionPriceString

    return (
      <AddToSubscriptionConfirmation
        currentSubscription={currentSubscription}
        onConfirmation={handleAddToSubscription}
        triggeringButtonRender={openPopup => (
          <Button
            size={size}
            variant={variant}
            color={color}
            data-cy={dataCy}
            fullWidth={fullWidth}
            disabled={subscriptionMutations.addItem?.isLoading}
            onClick={e => {
              if (e) {
                e.stopPropagation()
                e.preventDefault()
              }
              if (textToShowForAuthenticated === 'Gift Proven') {
                const anchorTarget = document.getElementById('giftCertificatesSection')
                anchorTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
                return
              }
              openPopup(e)
            }}
            sx={{ ...sx, width: fullWidth ? sx.width : 'fit-content' }}
          >
            {getButtonText({ defaultPrice, fullPrice, isAddingToSubscription: true })}
            {subscriptionMutations.addItem.isLoading && (
              <CircularProgress size={20} sx={{ ml: 1 }} />
            )}
          </Button>
        )}
      />
    )
  }

  if (!shopifyProductPrice) return null

  // FOR NOW we add to the cart all the time
  // if (isSubscriptionAction) {
  //   // is subscription selected and has a subscription
  //   const hasActiveSubscription = activeSubscriptions.length > 0
  //   if (hasActiveSubscription) {
  //     // and there is a subscription where it can be added, so product will be added to current subscription
  //     // TODO for now it take the first subscription of the list
  //     return renderAddToCurrentSubscriptionButton(activeSubscriptions[0], shopifyProductPrice)
  //   }
  // }

  return renderAddToCartButton(shopifyProductPrice)
}

ProvenButtonAddToCart.propTypes = {
  textToShowForAuthenticated: propTypes.string,
  textToShowForUnauthenticated: propTypes.string,
  addPriceWhenIsAuthenticated: propTypes.bool,
  addSavingCancellingAndShippingLegend: propTypes.bool,
  forceSubscriptionAction: propTypes.bool,
  intent: propTypes.string,
  productId: propTypes.string,
  variant: propTypes.string,
  fullWidth: propTypes.bool,
  size: propTypes.string,
  color: propTypes.string,
  showPrice: propTypes.bool
}

export default ProvenButtonAddToCart
