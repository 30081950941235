import React, { useRef, useState } from 'react'
import { Image } from 'components/builder/blocks/shared/Image'
import PropTypes from 'prop-types'
import { Box, Chip, Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import Slider from 'react-slick'
import { createProvenTheme } from '../../../../styles/theme-proven'
import './styles.scss'
import ProvenIconWithText from '../../proven-icon-with-text'
import YotpoStars from '../../yotpo-stars'
import ProductDetailSlide from '../../../account/congratulationsAB/C/results/product-slide-detail'
import { onDesktop } from '../../../../styles/theme-proven/responsive'
import { ProductProvider } from '@shopify/hydrogen-react'
import propTypes from 'prop-types'

const themeProven = createProvenTheme()

function ProductDetails({
  product,
  title,
  subtitle,
  description,
  size,
  carouselImagesUrls,
  subscribeAndSaveSelector,
  actionButton,
  legendBelowButton,
  renderIngredientsListDrawer,
  tags,
  horizontallyReversed,
  yotpoProduct
}) {
  const imageClickRef = useRef(null)
  const [isFullIngredientsOpen, setFullIngredientsOpen] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

  const settings = {
    customPaging: function (i) {
      const image = carouselImagesUrls[i]
      return (
        <Box sx={{ position: 'relative' }}>
          <Image
            lazy
            aspectRatio={1}
            image={image}
            backgroundSize="cover"
            backgroundPosition="center"
            key={i}
          />
        </Box>
      )
    },
    dots: true,
    infinite: true,
    dotsClass: 'slick-dots slick-thumb',
    speed: 400,
    slidesToShow: isMdUp ? 1 : 1.3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false
  }

  const handleClickImage = imageIndex => {
    if (imageClickRef.current) setSelectedImageIndex(imageIndex)
  }

  return (
    <ProductProvider data={product?.metadata?.shopifyProduct || product}>
      <Box
        className="congratulation-product1111"
        sx={{
          pt: 6,
          pb: 12,
          display: 'grid',
          gridTemplateColumns: isLgUp
            ? 'repeat(12, 1fr)'
            : isMdUp
            ? 'repeat(8, 1fr)'
            : 'repeat(4, 1fr)',
          gridTemplateRows: 'auto auto auto',
          gap: {
            md: 4
          },
          gridTemplateAreas: {
            xs: `"image image image image"
            "description description description description"`,
            md: horizontallyReversed
              ? `"description description description description image image image image"`
              : `"image image image image description description description description"`,
            lg: horizontallyReversed
              ? `"description description description description description . image image image image image image"`
              : `"image image image image image image . description description description description description"`
          },
          [isLgUp]: {
            pt: 16,
            pb: 16
          }
        }}
      >
        {/**
         * https://github.com/kenwheeler/slick/issues/982
         * min-width: 0 and min-height: 0
         */}
        <Box
          gridArea="image"
          sx={{
            marginBottom: { xs: 4, md: 0 },
            minWidth: 0,
            minHeight: 0,
            '& .slick-track': { left: '21vw', [onDesktop]: { left: 0 } },
            '& .slick-initialized .slick-slide': { px: 1 }
          }}
        >
          <Slider {...settings}>
            {(carouselImagesUrls || []).map((image, i) => (
              <Box
                sx={{ position: 'relative', cursor: 'pointer' }}
                key={i}
                onMouseDown={() => {
                  imageClickRef.current = true
                }}
                onMouseMove={() => {
                  imageClickRef.current = false
                }}
                onMouseUp={() => handleClickImage(i)}
              >
                <Image aspectRatio={1} image={image} backgroundSize="cover" />
              </Box>
            ))}
          </Slider>
        </Box>
        <Box gridArea="description" sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Typography variant="title1" color={themeProven.palette.gray.blueGray}>
              {subtitle}
            </Typography>
            <Typography
              component="h1"
              variant={isLgUp ? 'h2' : 'h3'}
              sx={{
                marginTop: 1,
                marginBottom: { xs: 3, lg: 2 },
                textTransform: 'inherit'
              }}
              color="gray.elysian"
            >
              {title}
            </Typography>
            {yotpoProduct && <YotpoStars {...yotpoProduct} />}
            <Typography
              variant="body2"
              color={themeProven.palette.gray.blueGray}
              dangerouslySetInnerHTML={{
                __html: description
              }}
            ></Typography>
            {size && (
              <Typography
                variant="body2"
                color={themeProven.palette.gray.blueGray}
                sx={{ marginTop: 2, mb: 6 }}
              >
                Size: {size}
              </Typography>
            )}
            {tags && !!tags.length && (
              <Grid container spacing={1} sx={{ mt: { xs: 1, lg: 3 }, mb: 4 }}>
                {(tags || [])
                  .filter(tag => tag)
                  .map((tag, index) => (
                    <Grid item key={index}>
                      <Chip label={tag} color="info" />
                    </Grid>
                  ))}
              </Grid>
            )}
            {renderIngredientsListDrawer && (
              <Box sx={{ mb: 6 }}>
                <Typography
                  variant="hyperlink"
                  color="gray.blueGray"
                  onClick={() => setFullIngredientsOpen(true)}
                >
                  Full Ingredients List +
                </Typography>
              </Box>
            )}
            {subscribeAndSaveSelector && (
              <Box
                data-testid="product-detail-subscribe-and-save-selector-container-testId"
                sx={{ mt: 8.5 }}
              >
                {subscribeAndSaveSelector}
              </Box>
            )}
            <Box sx={{ textAlign: { xs: 'center', lg: 'left' }, mt: 6 }}>{actionButton}</Box>
            <Stack
              direction="row"
              gap={3}
              pt={3}
              sx={{ justifyContent: { xs: 'center', lg: 'flex-start' } }}
            >
              {legendBelowButton ? (
                <Typography variant="footnote" color="gray.bluegray">
                  {legendBelowButton}
                </Typography>
              ) : (
                <>
                  <ProvenIconWithText
                    name="shipping"
                    type="system"
                    gap={1}
                    color="gray.elysian"
                    iconSize="1.125rem"
                  >
                    <Typography variant="footnote" color="gray.bluegray">
                      Free shipping
                    </Typography>
                  </ProvenIconWithText>
                  <ProvenIconWithText
                    name="free-reformulations"
                    type="system"
                    gap={1}
                    color="gray.elysian"
                    iconSize="1.125rem"
                  >
                    <Typography variant="footnote" color="gray.bluegray">
                      Free reformulation
                    </Typography>
                  </ProvenIconWithText>
                </>
              )}
            </Stack>
          </Box>
        </Box>
        {renderIngredientsListDrawer &&
          renderIngredientsListDrawer(isFullIngredientsOpen, () => setFullIngredientsOpen(false))}
        {selectedImageIndex !== null && (
          <ProductDetailSlide
            imageList={carouselImagesUrls}
            selectedImageIndex={selectedImageIndex}
            handleClose={() => setSelectedImageIndex(null)}
          />
        )}
      </Box>
    </ProductProvider>
  )
}

ProductDetails.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  size: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([propTypes.string, propTypes.object]).isRequired
    })
  ).isRequired,
  actionButton: PropTypes.node.isRequired,
  subscribeAndSaveSelector: PropTypes.node,
  renderIngredientsListDrawer: PropTypes.func,
  tags: PropTypes.array,
  horizontallyReversed: PropTypes.bool
}

export default ProductDetails
