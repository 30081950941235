//use this file version in case of conflicts (ENG-1608)
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toLower } from 'lodash'
import useActiveCampaign from 'hooks/use-active-campaign'
import { Box, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { createProvenTheme } from '../../../styles/theme-proven'
import Accessory from './accessory'
import { useShopProducts } from 'services/shopify/hooks'

const themeProven = createProvenTheme()

const SHOP_PRODUCT_BESTSELLER_LABEL = 'bestseller'
const SHOP_PRODUCT_NEW_LABEL = 'new'

function Badge({ defaultBadge }) {
  const cmBadgeSx = {
    background: themeProven.palette.secondary.main,
    color: themeProven.palette.gray.black
  }
  const bestSellerBadgeSx = {
    background: themeProven.palette.secondary.main,
    color: themeProven.palette.gray.elysian,
    textTransform: 'uppercase'
  }
  const newBadgeSx = {
    background: themeProven.palette.primary.main,
    color: themeProven.palette.gray.white
  }
  let text = defaultBadge
  let badgeSx
  const activeCampaign = useActiveCampaign()
  const isCm = ['cybermonday'].includes(activeCampaign?.id)
  if (isCm) {
    text = '30% Off Limited-Time'
    badgeSx = cmBadgeSx
  } else if (defaultBadge && defaultBadge.toLowerCase() === SHOP_PRODUCT_BESTSELLER_LABEL) {
    badgeSx = bestSellerBadgeSx
  } else if (defaultBadge && defaultBadge.toLowerCase() === SHOP_PRODUCT_NEW_LABEL) {
    badgeSx = newBadgeSx
  } else {
    return null
  }

  return (
    <Box sx={{ ...badgeSx, borderRadius: '4px', px: 1, py: 0.25 }}>
      <Typography variant="title1" color="inherit">
        {text}
      </Typography>
    </Box>
  )
}

function OneTimeAddons({ title, paragraph, prefix_url }) {
  const { data: productsList } = useShopProducts()
  const history = useHistory()

  const isEmptyLabelValue = label => !label || label === '-'

  const handleClickProduct = productId => {
    history.push(`${prefix_url}/${toLower(productId)}`)
  }

  const getShopTags = tags => {
    return tags.filter(tag => ['bestseller', 'new'].includes(tag?.toLowerCase())).at(0)
  }

  return (
    <ThemeProvider theme={themeProven}>
      <Box sx={{ px: 0, mt: { xs: 2.5, md: 7.5 } }}>
        <Typography variant="h3" color="gray.black" sx={{ mb: 1, textAlign: 'center' }}>
          {title}
        </Typography>
        <Typography variant="body2" color="gray.black" sx={{ mb: 5, textAlign: 'center' }}>
          {paragraph}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', lg: 'flex-start' },
            width: '100%',
            flexWrap: 'wrap',
            columnGap: 6.125,
            rowGap: 2,
            mx: 'auto'
          }}
        >
          {productsList?.map(accessory => {
            const defaultBadge = getShopTags(accessory?.tags)
            return (
              <Accessory
                key={`accessory-${accessory.id}`}
                sx={{
                  width: {
                    xs: '100%',
                    sm: '50%',
                    md: '50%',
                    lg: 'calc(86% / 3)',
                    xl: '21%',
                    xxl: '17%'
                  },
                  maxWidth: '242px'
                }}
                badge={
                  isEmptyLabelValue(defaultBadge) ? null : <Badge defaultBadge={defaultBadge} />
                }
                product={accessory}
              />
            )
          })}
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default OneTimeAddons
