import React from 'react'
import Ingredient from 'components/account/dashboard/shared/ingredient'
import './styles.scss'
const ProductIngredients = ({
  tags,
  ingredients,
  hideTargetingSection,
  hideIngredientsSection
}) => {
  const tagNoEmpty = (tags || []).filter(t => !!t)
  const ingredientsNoEmpty = (ingredients || []).filter(t => !!t)

  return (
    <section styleName="container">
      {hideTargetingSection && (
        <div styleName="tags-wrapper">
          <div styleName="subtitle">Targeting:</div>
          <div styleName="tags">
            {tagNoEmpty.map((tag, i) => (
              <span key={`tag${i}`}>{tag}</span>
            ))}
          </div>
        </div>
      )}

      {hideIngredientsSection && (
        <div styleName="ingredients-wrapper">
          <div styleName="subtitle">Your Active Ingredients:</div>
          <div styleName="ingredients">
            {ingredientsNoEmpty.slice(0, 3).map((ing, i) => (
              <Ingredient
                title={ing.ingredient_name}
                subTitle={ing.ingredient_concentration}
                image={ing.ingredient_img}
                key={`ingredient-${i}`}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  )
}

export default ProductIngredients
