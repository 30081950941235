import React, { useState } from 'react'
import propTypes from 'prop-types'
import { Stack, Box, Typography, Button, ButtonBase } from '@mui/material'
import { createProvenTheme } from '../../../../styles/theme-proven'
import { Money, ProductProvider } from '@shopify/hydrogen-react'
import { CDN_IMG } from 'constants/endpoints'
import { useCartActions } from 'services/shopify/hooks'

const themeProven = createProvenTheme()
const spacing = themeProven.spacing

function Accessory({ product, badge, sx = {} }) {
  const [onHover, setOnHover] = useState()
  const cartActions = useCartActions()

  const toggleHover = newState => {
    if (newState !== onHover) setOnHover(newState)
  }

  return (
    <ProductProvider data={product}>
      <Stack
        position="relative"
        gap={1}
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          ...sx,
          backgroundColor: themeProven.palette.gray.beige,
          pb: 3,
          mx: { xs: -2, lg: 0 }
        }}
      >
        {badge && (
          <Box position="absolute" sx={{ top: spacing(3), left: spacing(3) }} zIndex={2}>
            {badge}
          </Box>
        )}
        <Box
          pt={3}
          sx={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}
          onMouseEnter={() => toggleHover(true)}
          onMouseLeave={() => toggleHover(false)}
        >
          <Box sx={{ position: 'relative', width: spacing(24.25), height: spacing(21.5) }}>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 'auto',
                left: 'auto',
                opacity: onHover ? 0 : 1
              }}
              component="img"
              src={`${CDN_IMG + product.imageShopDesktop?.value}`}
              width={{ xs: '100%', md: spacing(25.5) }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                right: 'auto',
                left: 'auto',
                opacity: onHover ? 1 : 0
              }}
              component="img"
              src={`${CDN_IMG + product.imageShopDesktopHover?.value}`}
              width={{ xs: '100%', md: spacing(25.5) }}
            />
          </Box>
          {onHover && (
            <Box
              as={ButtonBase}
              sx={{
                position: 'absolute',
                display: 'flex',
                width: '100%',
                height: '40px',
                background: '#323232',
                opacity: 0.5,
                bottom: 0,
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                color: 'white',
                cursor: 'pointer',
                borderRadius: '0px 0px 4px 4px'
              }}
              href={`/${product.slug.value}`}
            >
              View Details
            </Box>
          )}
        </Box>
        <Typography
          mt={2}
          height={spacing(6.75)}
          variant="title2"
          maxWidth={spacing(30)}
          color="gray.elysian"
          textAlign="center"
        >
          {product.title}
        </Typography>
        <Money
          as={Typography}
          data={product.variants.nodes[0].price}
          mb={2}
          variant="title1"
          color="primary.main"
        />

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            cartActions.add({ product, sellingPlanId: product.initialSellingPlanId })
          }}
        >
          ADD ONCE
        </Button>
      </Stack>
    </ProductProvider>
  )
}

Accessory.propTypes = {
  product: propTypes.object.isRequired,
  badge: propTypes.func,
  sx: propTypes.object
}

export default Accessory
